<template>
  <div class="dashboard-lab-payment">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="my-account-wrap">
          <div class="columns content-my-account-wrap">
            <lab-menu></lab-menu>
            <div class="column is-12 right-column">
              <h3 class="title is-4 pb-4">Informaciones del laboratorio</h3>
              <div class="columns is-multiline">
                <div class="column is-12 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Razón social*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Email*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Teléfono*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Dirección*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Dirección 2*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="select is-primary is-full-width">
                    <select>
                      <option>País*</option>
                      <option>With options</option>
                    </select>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="select is-primary is-full-width">
                    <select>
                      <option>Provincia*</option>
                      <option>With options</option>
                    </select>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Ciudad*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Código Postal*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'NIF*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Nombre del titular de la cuenta*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Código IBAN*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'BIC/SWIFT*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>

                <div class="column is-6 is-offset-6 mb-3">
                  <button class="button is-primary is-fullwidth is-medium" @click="goTo('/checkout/oreder-completed')">Guardar</button>
                </div>
              </div>

              <hr>

              <h3 class="title is-4">Descripción</h3>
              <p class="pb-4">
                Cras mattis consectetur purus sit amet fermentum. Etiam porta sem malesuada magna mollis euismod. Donec sed odio dui. Curabitur blandit tempus porttitor.
              </p>
              <p>
                <a href="#" class="is-underlined">Instrucciones de facturación</a>
              </p>

              <hr>

              <h3 class="title is-5 is-uppercase pb-4">Pedidos completados</h3>
              <div class="columns year-archive-wrap is-multiline">
                <div class="column is-12">
                  <p class="title is-5 has-text-weight-bold is-uppercase">2021</p>
                </div>
                <div class="column is-8 pb-3">
                  <p>Abril</p>
                </div>
                <div class="column is-4 has-text-right pb-3">
                  <button class="icon-button">
                    <span class="icon icon-download"></span> <span class="btn-label">Descarga</span>
                  </button>
                </div>

                <div class="column is-8 pb-3">
                  <p>Marzo</p>
                </div>
                <div class="column is-4 has-text-right pb-3">
                  <button class="icon-button">
                    <span class="icon icon-download"></span> <span class="btn-label">Descarga</span>
                  </button>
                </div>

                <div class="column is-8 pb-3">
                  <p>Febrero</p>
                </div>
                <div class="column is-4 has-text-right pb-3">
                  <button class="icon-button">
                    <span class="icon icon-download"></span> <span class="btn-label">Descarga</span>
                  </button>
                </div>

                <div class="column is-8 pb-3">
                  <p>Enero</p>
                </div>
                <div class="column is-4 has-text-right pb-3">
                  <button class="icon-button">
                    <span class="icon icon-download"></span> <span class="btn-label">Descarga</span>
                  </button>
                </div>
              </div>
              <hr class="light">
              <div class="columns year-archive-wrap is-multiline">
                <div class="column is-12">
                  <p class="title is-5 has-text-weight-bold is-uppercase">2020</p>
                </div>
                <div class="column is-8 pb-3">
                  <p>Diciembre</p>
                </div>
                <div class="column is-4 has-text-right pb-3">
                  <button class="icon-button">
                    <span class="icon icon-download"></span> <span class="btn-label">Descarga</span>
                  </button>
                </div>

                <div class="column is-8 pb-3">
                  <p>Noviembre</p>
                </div>
                <div class="column is-4 has-text-right pb-3">
                  <button class="icon-button">
                    <span class="icon icon-download"></span> <span class="btn-label">Descarga</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import LabMenu from '@/components/Laboratory/LabMenu.vue'
import InputText from '@/components/FormElements/InputText.vue'

export default {
  name: 'LaboratoryLabPayment',
  components: {
    HeroImage,
    Footer,
    LabMenu,
    InputText
  },
  data () {
    return {
      email: ''
    }
  },
  methods: {
    logout () {
      const self = this
      this.$store.dispatch('user/logOut', {
        cb: () => {
          self.goTo('/')
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on the log out'
            }
          })
        }
      })
    },
    goTo (path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard-lab-payment{
  width: 100%;
  section{
    width: 100%;
    padding: 0px;
    &.over-hero{
      position: relative;
      margin-top: -430px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .my-account-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-my-account-wrap{
        padding: 0px;
        flex-direction: column;
        .has-background-light{
          border-radius: 4px 0px 0px 4px;
        }
        .right-column{
          padding: 80px;
          padding-top: 40px;
          .icon-button{
            position: relative;
            background-color: transparent;
            border: 0px;
            [class^="icon"]{
              font-weight: bold;
              font-size: 1.3rem;
              position: relative;
              top: 5px;
            }
            .btn-label{
              font-size: 1rem;
              font-family: Avenir, Helvetica, Arial, sans-serif;
              font-family: "basic-sans",sans-serif;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>
