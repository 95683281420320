import { render, staticRenderFns } from "./PaymentDetails.vue?vue&type=template&id=0ef064c7&scoped=true&"
import script from "./PaymentDetails.vue?vue&type=script&lang=js&"
export * from "./PaymentDetails.vue?vue&type=script&lang=js&"
import style0 from "./PaymentDetails.vue?vue&type=style&index=0&id=0ef064c7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef064c7",
  null
  
)

export default component.exports